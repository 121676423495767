import React from "react";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
import moment from "moment";
import Loader from "react-loader-spinner";

const ChangeRequestForm = () => {
  const [userdata, setuserdata] = useState({ status: "delivered" });
  const [list, setlist] = useState([]);
  const [filterlist, setfilterlist] = useState([]);

  const objectFormation = (e) => {
    const { name, value } = e.target;
    setuserdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getdata = () => {
    axios
      .get(`https://sheetdb.io/api/v1/wqcy32pv18kvq`)
      .then(function (response) {
        setlist(response.data);
        setfilterlist(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [Submodule, setSubmodule] = useState([]);
  const [FiltersubModule, setFiltersubModule] = useState([]);
  const [loading, setloading] = useState(false);

  const [Menu, setMenu] = useState([
    // {
    //   text: "Dashboard",
    //   submodule: [],
    // },
    {
      text: "Vouchers",
      submodule: [
        { text: "General" },
        { text: "Investment" },
        {
          text: "Journal",
        },
        { text: "Commission" },
        { text: "Land" },
        { text: "Unit" },
        { text: "Deal" },
      ],
    },
    {
      text: "Accounts",
      submodule: [
        { text: "Chart Of Accounts" },
        { text: "Payables" },
        { text: "Receivables" },

        { text: "Recovery Sheet" },
        { text: "Ledger" },
        { text: "Reports" },
      ],
    },
    {
      text: "Sale",
      submodule: [
        { text: "Customers" },
        { text: "Affiliate" },
        { text: "Contract" },
        {
          text: "Transfer Contract",
        },
        { text: "Cancel Contract" },
        { text: "Deals Sold" },
      ],
    },
    {
      text: "Purchase",
      submodule: [
        { text: "Sellers" },
        { text: "Land Sellers" },
        { text: "Land" },
        { text: "Investors" },
        { text: "Investment in Project" },
        { text: "Purchase" },
      ],
    },
    {
      text: "Inventory",
      submodule: [{ text: "Project" }, { text: "Unit" }, { text: "Block" }],
    },
  ]);
  useEffect(() => {
    getdata();
  }, []);

  const addUserData = () => {
    let size = Object.keys(userdata).length;

    if (size < 7) {
      toast.info("Please fill all the fields!");
    } else {
      setloading(true);
      axios
        .post("https://sheetdb.io/api/v1/wqcy32pv18kvq", userdata)
        .then(function (response) {
          setuserdata({});
          document.getElementById("date").value = "";
          document.getElementById("module").selectedIndex = 0;
          document.getElementById("submodule").selectedIndex = 0;
          document.getElementById("priority").selectedIndex = 0;
          document.getElementById("description").value = "";
          document.getElementById("title").value = "";
          getdata();
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    }
  };

  const onTextChange = () => {
    if (
      document.getElementById("searchbySubmodule").value === "" &&
      document.getElementById("searchbymodule").value === "" &&
      document.getElementById("searchbypriority").value === "" &&
      document.getElementById("searchbyStatus").value === ""
    ) {
      setfilterlist(list);
    } else {
      let suggestions = [];
      {
        const regex = new RegExp(
          document.getElementById("searchbySubmodule").value,
          "i"
        );
        const regex1 = new RegExp(
          document.getElementById("searchbypriority").value,
          "i"
        );
        const regex2 = new RegExp(
          document.getElementById("searchbyStatus").value,
          "i"
        );
        const regex3 = new RegExp(
          document.getElementById("searchbymodule").value,
          "i"
        );

        suggestions = list
          .sort()
          .filter(
            (val) =>
              (document.getElementById("searchbySubmodule").value === "" ||
                regex.test(val.submodule)) &&
              (document.getElementById("searchbypriority").value === "" ||
                regex1.test(val.priority)) &&
              (document.getElementById("searchbyStatus").value === "" ||
                regex2.test(val.status)) &&
              (document.getElementById("searchbymodule").value === "" ||
                regex3.test(val.module))
          );
      }

      setfilterlist(suggestions);
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <div className="tableContainer2 mt-3 Sitka h_full">
        <div className="row mt-3 px-3">
          <div className="col-1 marginTop10">Date</div>
          <div className="col-3 marginTop5">
            <input
              className="input_border formInput"
              id="date"
              type="date"
              name="date"
              onChange={objectFormation}
            ></input>
          </div>
          <div className="col-8"></div>
          <div className="col-1 mt-2">Module</div>
          <div className="col-3 mt-2">
            <select
              className="input_border formInput"
              id="module"
              name="module"
              onChange={(e) => {
                if (e.target.value !== "") {
                  setSubmodule(
                    JSON.parse(
                      e.target.options[e.target.selectedIndex].dataset.sub
                    )
                  );
                }
                objectFormation(e);
              }}
            >
              <option value={""}>Select</option>
              {Menu.map((val, index) => (
                <option
                  key={index}
                  value={val.text}
                  data-sub={JSON.stringify(val.submodule)}
                >
                  {val.text}
                </option>
              ))}
            </select>
          </div>

          <div className="col-1 pl-0 pr-0 ml-0 mr-0 mt-2">Sub-Module</div>
          <div className="col-3 mt-2">
            <select
              className="input_border formInput"
              id="submodule"
              name="submodule"
              onChange={objectFormation}
            >
              <option value={""}>Select</option>
              {Submodule.map((sub, index) => (
                <option key={index} value={sub.text}>
                  {sub.text}
                </option>
              ))}
            </select>
          </div>

          <div className="col-1 mt-2">Priority</div>
          <div className="col-3 mt-2">
            <select
              className="input_border formInput "
              type="text"
              placeholder="heading"
              id="priority"
              name="priority"
              onChange={objectFormation}
            >
              <option value="">Select</option>
              <option value="5">5</option>
              <option value="4">4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
            </select>
          </div>

          <div className="col-12">
            <hr />
          </div>

          <div className="col-1 mt-2">Title</div>
          <div className="col-3 mt-2">
            <input
              className="input_border formInput widthfull"
              type="text"
              placeholder="heading"
              id="title"
              name="title"
              onChange={objectFormation}
            ></input>
          </div>
          <div className="col-8"></div>
          <div className="col-1 mt-2">Description</div>
          <div className="col-11 mt-2">
            <textarea
              id="description"
              className="input_border formInput widthfull height40"
              name="description"
              onChange={objectFormation}
            ></textarea>
          </div>

          <div className="col-12 text-right">
            {loading ? (
              <Loader
                type="Oval"
                color="black"
                height={30}
                width={30}
                radius={12}
              />
            ) : (
              <button
                style={{ padding: "3px 18px", marginRight: "45px" }}
                className="tabletopbuttonnew greenbutton "
                id="savebtn"
                onClick={addUserData}
              >
                {" "}
                Submit{" "}
              </button>
            )}
          </div>

          <div className="col-12 py-2">
            <hr />
          </div>
          <div className="col-3">
            <div>Module</div>

            <select
              className="input_border formInput "
              id="searchbymodule"
              onChange={(e) => {
                if (e.target.value !== "") {
                  setFiltersubModule(
                    JSON.parse(
                      e.target.options[e.target.selectedIndex].dataset.sub
                    )
                  );
                }
                onTextChange(e);
              }}
            >
              <option value={""}>All</option>
              {Menu.map((val, index) => (
                <option
                  key={index}
                  value={val.text}
                  data-sub={JSON.stringify(val.submodule)}
                >
                  {val.text}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <div>Sub Module</div>

            <select
              className="input_border formInput "
              id="searchbySubmodule"
              onChange={onTextChange}
            >
              <option value="">All</option>
              {FiltersubModule.map((sub, index) => (
                <option key={index} value={sub.text}>
                  {sub.text}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <div>Priority</div>

            <select
              className="input_border formInput "
              id="searchbypriority"
              onChange={onTextChange}
            >
              <option value="">All</option>
              <option value="5">5</option>
              <option value="4">4</option>
              <option value="3">3</option>
              <option value="2">2</option>
              <option value="1">1</option>
            </select>
          </div>
          <div className="col-3">
            <div>Status</div>

            <select
              className="input_border formInput "
              id="searchbyStatus"
              onChange={onTextChange}
            >
              <option value="">All</option>
              <option value="in-progress">in-progress</option>
              <option value="in-review">in-review</option>
              <option value="completed">completed</option>
              <option value="rejected">rejected</option>
            </select>
          </div>

          <div className="col-12 mt-1 rowoverflow">
            <table
              className="table tableCash table-hover"
              style={{ width: "-webkit-fill-available" }}
            >
              <thead class="purple whiteText">
                <tr>
                  <th className="text-left th1">Sr</th>
                  <th className="text-left th1">Date</th>
                  <th className="text-left th1">Module</th>
                  <th className="text-left th1">Sub-Module</th>
                  <th className="text-left th1">Title</th>
                  <th className="text-left th1">Description</th>
                  <th className="text-left th1">Priority</th>
                  <th className="text-left th1">Delivery Date</th>
                  <th className="text-left th1">Status</th>
                </tr>
              </thead>
              <tbody>
                {filterlist?.map((x, idx) => {
                  if (x.module !== "") {
                    return (
                      <tr>
                        <td>{idx + 1}</td>
                        <td>{moment(x.date).format("DD-MMM-YYYY")}</td>
                        <td>{x.module}</td>
                        <td>{x.submodule}</td>
                        <td>{x.title}</td>
                        <td>{x.description}</td>
                        <td>{x.priority}</td>
                        <td>{moment(x.deliverydate).format("DD-MMM-YYYY")}</td>
                        <td
                          className={
                            x.status === "delivered"
                              ? ""
                              : x.status === "completed"
                              ? "greencell"
                              : x.status === "rejected"
                              ? "redcell"
                              : x.status === "in-review"
                              ? "yellowcell"
                              : x.status === "in-progress"
                              ? "bluecell"
                              : ""
                          }
                        >
                          {x.status}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeRequestForm;
